import React from "react"
import { CVJSON } from "../components/cv"

const CV = () => {
  return (
    <p>
      <CVJSON />
    </p>
  )
}

export default CV
