import React from "react"
import SEO from "../components/seo"

export const CVJSON = () => {
  const details = {
    basics: {
      name: "Ruairidh Wynne-McHardy",
      label: "Senior Engineer",
      email: "ruairidh@ruairidh.dev",
      phone: "Contact Me For This",
      website: "https://ruairidh.dev",
      summary: "Senior Engineer at AskPorter",
      profiles: [
        {
          network: "Twitter",
          username: "ruairidhwm",
          url: "https://twitter.com/ruairidhwm",
        },
        {
          network: "GitHub",
          username: "ruairidhwm",
          url: "https://github.com/ruairidhwm",
        },
      ],
    },
    work: [
      {
        company: "AskPorter",
        position: "Senior Engineer",
        website: "http://askporter.co",
        startDate: "01-06-2020",
        endDate: "Present",
        summary: "Description...",
        highlights: [
          "Advocated for, and implemented a design system to standardise our components and presentation.",
          "Implemented Cypress testing across our Front End to provide more robust integration tests.",
          "Worked on our internal Developer Experience by creating GitHub PR templates to standardise what is included in a PR.",
          "Worked on Developer Experience by advocating for, and implementing an internal SDK which prevents reuse of code across projects and increases team efficiency. This is now used by several teams across multiple codebases.",
          "Assisted in converting our legacy processes to Infrastructure as Code via Terraform",
          "Worked on moving away from a monolithic architecture to implementing an event bus, and service-oriented codebases.",
          "Introduced GitHub Actions to the team and implemented these across our code to automate workflows.",
          "Implemented micro-frontends in React to increase the scalability of our front-end code. Introduced the concept to the team, and provided reference material for a smooth implementation.",
        ],
      },
    ],
    volunteer: [
      {
        organization: "Codebar",
        position: "Volunteer",
        website: "https://codebar.io/",
        startDate: "2019-01-01",
        endDate: "Present",
        summary:
          "Codebar's goal is to enable minority group members to learn programming in a safe and collaborative environment and expand their career opportunities.",
      },
    ],
    education: [
      {
        institution: "University",
        area: "Law",
        studyType: "LLB (Hons)",
        startDate: "September 2009",
        endDate: "July 2013",
        degree: "2:1",
      },
    ],
    languages: [
      {
        language: "English",
        fluency: "Native speaker",
      },
      {
        language: "French",
        fluency: "Fluent",
      },
      {
        language: "Spanish",
        fluency: "Intermediate",
      },
      {
        language: "Mandarin",
        fluency: "Beginner",
      },
    ],
  }
  return (
    <>
      <SEO title="CV" description="Ruairidh Wynne-McHardy's CV" />
      <pre>{JSON.stringify(details, null, 2)}</pre>
    </>
  )
}
